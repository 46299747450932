<template>
    <BModal
        id="serviceDetailsModal"
        ref="serviceDetailsModal"
        centered
        data-cy="serviceDetailsModal"
        modal-class="bar bar-primary"
        no-close-on-backdrop
        size="lg"
        static
        visible
        @hidden="closeDetailModal('service')"
    >
        <template #modal-header>
            <h3 class="mb-0 font-weight-bold text-break">
                Request Details for {{ selectedResult.vendor }}'s {{ selectedResult.service }}
            </h3>
        </template>

        <div v-if="!loading">
            <div class="row mb-3">
                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Company
                    </h4>
                    {{ selectedResult.company }}
                </div>

                <div class="col-6">
                    <h4 class="font-weight-bold">
                        Date
                    </h4>
                    {{ date(selectedResult.date) }}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Request
                    </h4>
                </div>

                <div class="col-2">
                    <h5 class="font-weight-bold">
                        Method
                    </h5>
                    {{ selectedResult.requestDetails.hasOwnProperty('method') ? selectedResult.requestDetails.method : 'Not Recorded' }}
                </div>

                <div
                    class="col-10"
                    style="overflow-x: auto"
                >
                    <h5 class="font-weight-bold">
                        URL
                    </h5>
                    {{ selectedResult.requestDetails.hasOwnProperty('url') ? selectedResult.requestDetails.url : 'Not Recorded' }}
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Payload
                    </h4>

                    <AceEditor
                        ref="editor"
                        :mode="typeof parseJson(selectedResult.payload) === 'string' ? 'text' : 'json'"
                        :options="editorOptions"
                        theme="chrome"
                        :value="formatted(parseJson(selectedResult.payload))"
                    />
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <h4 class="font-weight-bold">
                        Response
                    </h4>

                    <AceEditor
                        ref="editor"
                        :mode="isJsonResponse ? 'json' : 'text'"
                        :options="editorOptions"
                        theme="chrome"
                        :value="isJsonResponse ? formatted(selectedResult.jsonResponse) : selectedResult.response"
                    />
                </div>
            </div>
        </div>

        <div v-else>
            <FontAwesomeIcon
                far
                icon="spinner"
                pulse
            />
        </div>

        <template #modal-footer>
            <div class="flex flex-row align-items-center">
                <BButton
                    data-cy="closeModalBtn"
                    block
                    variant="secondary"
                    @click="closeDetailModal('service')"
                >
                    Close Details
                </BButton>
            </div>
        </template>
    </BModal>
</template>

<script>
    import {mapState} from 'vuex';

    import details from '@/mixins/details';

    export default {
        name: 'STServiceDetailsModal',
        mixins: [details],
        data() {
            return {
                loading: false
            };
        },
        computed: {
            ...mapState(['selectedResult'])
        },
        async created() {
            await this.initDetails('service');
        },
    };
</script>
